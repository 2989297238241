@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  src: url('./assets/fonts/ProximaNova-Regular.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Semibold';
  font-style: normal;
  src: url('./assets/fonts/ProximaNova-Semibold.otf') format('opentype');
  font-display: swap;
}

* {
  font-family: 'Proxima Nova', Corbel, Arial, sans-serif;
  font-synthesis: none;
}
